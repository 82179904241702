import { atom } from "recoil";
import { ATOM_ERROR_STATE_ID, ATOM_FLOW_COMPLETED_ID, ATOM_SESSION_ID } from "../constants";
import { ErrorState } from "../types";

export const sessionIdAtom = atom<string>({
  key: ATOM_SESSION_ID,
  default: "",
});

export const flowCompletedAtom = atom<boolean | undefined>({
  key: ATOM_FLOW_COMPLETED_ID,
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      const savedValue = sessionStorage.getItem(ATOM_FLOW_COMPLETED_ID);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? sessionStorage.removeItem(ATOM_FLOW_COMPLETED_ID)
          : sessionStorage.setItem(ATOM_FLOW_COMPLETED_ID, JSON.stringify(newValue));
      });
    },
  ],
});

export const errorStateAtom = atom<ErrorState>({
  key: ATOM_ERROR_STATE_ID,
  default: ErrorState.None,
  effects: [
    ({ setSelf, onSet }) => {
      const savedValue = sessionStorage.getItem(ATOM_ERROR_STATE_ID);
      if (savedValue != null) {
        setSelf(ErrorState[savedValue as keyof typeof ErrorState]);
      }

      onSet((newValue: ErrorState, _, isReset) => {
        isReset
          ? sessionStorage.removeItem(ATOM_ERROR_STATE_ID)
          : sessionStorage.setItem(ATOM_ERROR_STATE_ID, ErrorState[newValue]);
      });
    },
  ],
});
