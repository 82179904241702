import getPolicyOption from "@/shared/utils/getPolicyOption";
import { Box } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { HomeGRFX021NoPadding, PolicyDetailsCard } from "@racwa/react-components";
import { PolicyDetailsResponse } from "raci-claims-home-general-damage-clientproxy";
import { BASE_PDS_URL } from "raci-react-library";

interface PolicyDetailsCardProps {
  policyDetails: PolicyDetailsResponse;
}

const pdsText = "Product Disclosure Statement";

export const PolicyCard = ({ policyDetails }: PolicyDetailsCardProps) => {
  const policyOption = getPolicyOption(policyDetails, false);
  return (
    <div style={{ paddingTop: "8px" }}>
      <PolicyDetailsCard
        id={"summary"}
        policyTitle={policyOption.policyTitle}
        policySubtitle={policyOption.policySubtitle}
        minWidth={"50"}
        maxWidth={"200"}
        cardActionTitle={pdsText}
        cardActionLink={process.env.REACT_APP_RAC_PDS_PED_URL || BASE_PDS_URL}
        cardActionOnClick={() => {
          gtm(event(pdsText));
        }}
        showCancelledRibbon={false}
        properties={[
          { key: "Policy number", value: policyOption.policyNumber },
        ]}
        propertiesOrientation="column"
      >
        <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
          <HomeGRFX021NoPadding />
        </Box>
      </PolicyDetailsCard>
    </div>
  );
};
