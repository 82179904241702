import { SessionState } from "raci-react-library";
import { SubmitHandler, UseFormReturn } from "react-hook-form";

export enum MFEAction {
  Error = "Error",
  Submit = "Submit",
  TurnOnBackDrop = "TurnOnBackDrop",
  TurnOffBackDrop = "TurnOffBackDrop",
}

export interface ContactDetailsFormProps {
  form: UseFormReturn<ContactDetailsFormValues>;
  isLoading: boolean;
  firstName?: string;
  phoneType: "Mobile" | "Home" | "Work";
  maskedPhoneNumber?: string;
  maskedEmail?: string;
  onSubmit: SubmitHandler<ContactDetailsFormValues>;
  onMFEAction: (action: MFEAction, state?: ContactDetailsFormValues) => Promise<void>;
}

export interface ContactDetailsFormValues {
  contactNumber?: string | undefined;
  email?: string | undefined;
}

export type ContactDetailsState = SessionState<ContactDetailsFormValues>;
