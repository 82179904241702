import useStepperPage from "@/shared/hooks/useStepperPage";
import { RacwaStepperTemplate } from "@racwa/react-components";
import { RouteObject } from "react-router-dom";
import SpecificDamageForm from "./Form";
import useSpecificDamage from "./hooks/useSpecificDamage";
import { createPageTitle } from "@/shared/routing/TitleGuard";

export const pageName = "Specific damage";

export const SpecificDamage = () => {
  const props = useSpecificDamage();
  const stepperProps = useStepperPage();

  return (
    <RacwaStepperTemplate {...stepperProps}>
      <SpecificDamageForm {...props} />
    </RacwaStepperTemplate>
  );
};

export const SpecificDamageRoute = {
  path: "specific-damage",
  element: <SpecificDamage />,
  loader: () => (document.title = createPageTitle(pageName)),
} as const satisfies RouteObject;

export default SpecificDamage;
